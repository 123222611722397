import React from "react";
import { Link } from "react-router-dom";
import { Site_link, theme, title } from "../config/Config";

function Footer() {
  return (
    <footer className="main-footer fixed-bottom text-center">
      <strong>
        Copyright © {(new Date().getFullYear())} <Link onClick={() => window.open(Site_link) } style={{color:theme}}>{title} </Link>
      </strong>
      All rights reserved.
    </footer>
  );
}

export default Footer;
