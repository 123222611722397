import React from "react";
import { Link } from 'react-router-dom';
import { ShortLogo, logo, shotTitle, title } from "../config/Config";
import toasted from "../config/toast";



function Sidebar() {

  const logOut = () => {
    localStorage.clear('user')
    window.location.reload();
    toasted.success('LogOut Successfully!')
  }


  return (

    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to="/" className="brand-link">
          <span className="brand-text font-weight-light px-5">{shotTitle} - Admin</span>
        </Link>

        <div className="sidebar" id="sidebar_ul">
          <div className="user-panel" >
            <div className="image">
              <img src={ShortLogo} className="px-5" alt={title + ` Logo`} style={{ height: 'auto', width: '9.1rem' }} />
            </div>

          </div>
          {/* <div className="form-inline">
                    <div className="input-group" data-widget="sidebar-search">
                      <input className="form-control form-control-sidebar" disabled type="search" placeholder="Search" aria-label="Search" />
                      <div className="input-group-append">
                        <button className="btn btn-sidebar">
                          <i className="fas fa-search fa-fw" />
                        </button>
                      </div>
                    </div>
                  </div> */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">

              <li className="nav-item">
                <Link to="/" className="nav-link active">
                  <i className="fa-solid fa-table-cells px-1"></i>
                  <p> Dashboard </p>
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link to="#" className="nav-link" data-toggle="dropdown">
                  <i className="fa-sharp fa-solid fa-user px-1"></i>
                  <p> User Details <i className="right fas fa-angle-left" /> </p>
                </Link>
                <div className="dropdown-menu">
                  <li className="nav-item">
                    <Link to="/allusers" className="nav-link">
                      <p>All Users</p>
                    </Link>
                  </li>

                </div>
              </li>

              <li className="nav-item dropdown">
                <Link to="#" className="nav-link" data-toggle="dropdown">
                  <i className="fa-solid fa-id-card px-1"></i>
                  <p> KYC <i className="right fas fa-angle-left" /> </p>
                </Link>
                <div className="dropdown-menu">

                  <li className="nav-item">
                    <Link to="/pendingkyc" className="nav-link">
                      <p>Pending</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/approvedkyc" className="nav-link">
                      <p>Approved</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/rejectedkyc" className="nav-link">
                      <p>Rejected</p>
                    </Link>
                  </li>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link to="#" className="nav-link" data-toggle="dropdown">
                  <i className="fas fa-chart-bar px-1"></i>
                  <p> Market <i className="right fas fa-angle-left" /> </p>
                </Link>
                <div className="dropdown-menu">

                  <li className="nav-item">
                    <Link to="/mainmarket" className="nav-link">
                      <p>Main Market</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/manualToken" className="nav-link">
                      <p>Manual Token</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/gtcmarket" className="nav-link">
                      <p>GTC Market</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/createtoken" className="nav-link">
                      <p>Create Token</p>
                    </Link>
                  </li>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link to="#" className="nav-link" data-toggle="dropdown">
                  <i className="fas fa-sack-dollar px-1"></i>
                  <p> Fund Management <i className="right fas fa-angle-left" /> </p>
                </Link>
                <ul className="dropdown-menu">
                  <li className="nav-item">
                    <Link to="/fund-deposits" className="nav-link">
                      <p>Fund Deposits</p>
                    </Link>
                  </li>

                </ul>
              </li>



              <li className="nav-item dropdown">
                <Link to="#" className="nav-link" data-toggle="dropdown">
                  <i className="fa-solid fa-money-bill-transfer px-1"></i>
                  {/* <i class="fas fa-arrow-to-bottom"></i> */}
                  <p>Withdraw <i className="right fas fa-angle-left" /> </p>
                </Link>
                <div className="dropdown-menu">
                  <li className="nav-item">

                    <Link to="/withdrawhistory/Pending" className="nav-link">
                      <p>Withdraw Pending History</p>
                    </Link>
                  </li>
                  <li className="nav-item">

                    <Link to="/withdrawhistory/Approved" className="nav-link">
                      <p>Withdraw Approved History</p>
                    </Link>
                  </li>
                  <li className="nav-item">

                    <Link to="/withdrawhistory/Rejected" className="nav-link">
                      <p>Withdraw Rejected History</p>
                    </Link>
                  </li>

                </div>
              </li>

              <li className="nav-item dropdown">
                <Link to="#" className="nav-link" data-toggle="dropdown">
                  {/* <i className="fa-solid fa-money-bill-transfer px-1"></i> */}
                  <i class="fa-sharp fa-solid fa-chart-simple px-1"></i>
                  <p>Trade <i className="right fas fa-angle-left" /> </p>
                </Link>
                <div className="dropdown-menu">
                  <li className="nav-item">
                    <Link to="/trade-orders" className="nav-link">
                      <p>Orders</p>
                    </Link>
                  </li>

                </div>
              </li>

              <li className="nav-item dropdown">
                <Link to="#" className="nav-link" data-toggle="dropdown">
                  <i class="fas fa-clipboard-check px-1"></i>
                  <p>Activity Logs <i className="right fas fa-angle-left" /> </p>
                </Link>
                <div className="dropdown-menu">
                  <li className="nav-item">
                    <Link to="/activitylog" className="nav-link">
                      <p>Activity Logs</p>
                    </Link>
                  </li>
                </div>
              </li>

              <li className="nav-item dropdown">
                <Link to="#" className="nav-link" data-toggle="dropdown">
                  <i className="fas fa-people-arrows px-1"></i>
                  <p>P2P <i className="right fas fa-angle-left" /> </p>
                </Link>
                <div className="dropdown-menu">
                  <li className="nav-item">
                    <Link to="/p2phistory" className="nav-link">
                      <p>P2P History</p>
                    </Link>
                  </li>
                </div>
              </li>



              {/* LOG-OUT */}
              <li className="nav-item">
                <Link className="nav-link " onClick={logOut}>
                  <i className="fa-solid fa-right-from-bracket px-1"></i>
                  <p> Logout </p>
                </Link>
              </li>

            </ul>

          </nav>
        </div>
      </aside>
    </div>

  );
}

export default Sidebar;
