import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Toaster } from 'react-hot-toast';


import {
  PrivateRoutes, Login, Dashboard, Allusers, Approvedkyc, Pendingkyc, Rejectedkyc, Headerbar, Sidebar, Footer,
  MainMarket, GtcMarket, Gtcform, FundDeposits, CreateToken, ManualToken, WithdrawHistory, Activitylog, TradeOrders, P2pHistory, TokenEditForm
} from './Components';

function PrivateLayout() {
  return (
    <>
      <Headerbar />
      <Sidebar />
      <Outlet />
      <Footer />
    </>
  )
}
function PublicLayout() {
  return <Outlet />
}

function App() {



  return (
    <>
      <div className="wrapper">

        <BrowserRouter basename='/'>
          <Routes>

            {/* <Route element={<PrivateRoutes />}> */}
              <Route path="/" element={<PrivateLayout />}>
                <Route index element={<Dashboard />} />
                <Route exact path="*" element={<Dashboard />} />
                <Route exact path="/allusers" element={<Allusers />} />
                <Route exact path="/approvedkyc" element={<Approvedkyc />} />
                <Route exact path="/Pendingkyc" element={<Pendingkyc />} />
                <Route exact path="/rejectedkyc" element={<Rejectedkyc />} />
                <Route exact path="/mainmarket" element={<MainMarket />} />
                <Route exact path="/manualToken" element={<ManualToken />} />
                <Route exact path="/manualToken/:id" element={<TokenEditForm />} />
                <Route exact path="/gtcmarket" element={<GtcMarket />} />
                <Route exact path="/gtcmarket/:id" element={<Gtcform />} />
                <Route exact path="/fund-deposits" element={<FundDeposits />} />
                <Route exact path="/createtoken" element={<CreateToken />} />
                <Route exact path="/withdrawhistory/:type" element={<WithdrawHistory />} />
                <Route exact path="/activitylog" element={<Activitylog />} />
                <Route exact path="/trade-orders" element={<TradeOrders />} />
                <Route exact path="/p2phistory" element={<P2pHistory />} />
              </Route>
            {/* </Route> */}

            <Route path="/login" element={<PublicLayout />}>
              <Route index element={<Login />} />
            </Route>

          </Routes>
        </BrowserRouter>
        <Toaster />


      </div>
    </>

  );
}

export default App;
