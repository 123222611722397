import site_logo from '../Components/Assets/images/logo.png'
import site_logo_short from '../Components/Assets/images/shortLogo.png'
import arrowUp from '../Components/Assets/images/arrowupdark.svg';
import arrowDown from '../Components/Assets/images/arrowdown.svg.png';
import workInProgress from '../Components/Assets/images/workinprogress.png';
import NoImage from '../Components/Assets/images/noImage.jpg'


export const title = 'Yo Exchange';
export const shotTitle = 'YoEX';
export const ShortLogo = site_logo_short;
export const logo = site_logo;
export const up = arrowUp
export const down = arrowDown
export const progress = workInProgress
export const noImageFound = NoImage
export const theme = 'rgb(52 220 191)'
export const Site_link = "https://weboadmin.yoex.live/";
export const API_URL = 'https://api-admin.yoex.live/api/v1';
export const IMAGE_URL = 'https://api.yoex.live/images/';
export const TRON = 'https://tronscan.org/';
export const BSC = 'https://bscscan.com/';


